@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    html {
      font-family: 'DM Sans', sans-serif;
    }
}

.btn-font {
    font-family: "DM Sans", "Helvetica Neue Light", "Helvetica Neue", "Helvetica",
    "Arial", sans-serif;
}

.menu-item{
  @apply flex h-12 rounded-lg items-center hover:font-bold hover:text-white hover:bg-[#2943D5] hover:duration-300;
}

.menu-active{
  @apply bg-[#2943D5] text-white font-bold;
}

.menu-inactive{
  @apply bg-white text-[#8394B1];
}

.border-menu {
  border: 0.5px solid #A6B0C3;
  margin-top: 20px;
  margin-bottom: 20px;
}

.avatar-name{
  transform: translateX(0);
  transition: all 0.2s ease-in-out;
}

/* Make animation on hover */
.avatar:hover + .avatar-name{
  transform: translateX(-120px);
}
.avatar-name:hover{
  transform: translateX(-120px);
}
